<template>
  <div class="app de uc">
    <div class="hd">
      <span>返回</span>
    </div>

    <div class="ucinfo">

        <div class="avatar"></div>
        <span>nickname</span>
         欢迎您回来

    </div>

    <div class="main">
      <md-field>
        <md-cell-item title="我的预约" arrow />
        <md-cell-item title="我的活动" arrow />
        <md-cell-item title="我的收藏" arrow />
      </md-field>
    </div>



      <div class="copyright">
          copyright@2021 梧州群众艺术馆
      </div>


  </div>
</template>

<script>

export default {
  name: 'Home',

}
</script>
<style lang="scss" scoped>
.main{
  height: 0;
  flex-grow: 1;
}

</style>
